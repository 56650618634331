*{
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

#root, html, body{
  height: auto;
  width: 100vw;
  margin: 0;
  padding: 0;
  background-color: lightblue;
}


.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

